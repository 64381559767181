import {
  Dialog,
  DialogTitle,
  IconButton,
  DialogContent,
  DialogActions,
  styled,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import React from "react";
import Button from "@mui/material/Button";
import "./style.css";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

function Footer() {
  const [open, setOpen] = React.useState(false);
  const [openD, setOpenD] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleClickOpenD = () => {
    setOpenD(true);
  };
  const handleCloseD = () => {
    setOpenD(false);
  };

  return (
    <div>
      <React.Fragment>
        <BootstrapDialog
          maxWidth={"lg"}
          onClose={handleClose}
          aria-labelledby="customized-dialog-title"
          open={open}
        >
          <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
            Impressum
          </DialogTitle>
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
          <DialogContent dividers>
            <h4>Verantwortlich für den Inhalt dieser Webseite:</h4>
            Marvin Baumgart <br></br>
            Moritzstr. 8<br></br>
            10969, Berlin<br></br>
            Deutschland<br></br>
            <h4>Kontakt:</h4>
            E-Mail: marvinbaumgart18@gmail.com<br></br>
            Telefon: +49 162 189 6476<br></br>
            <h4>Umsatzsteuer-Identifikationsnummer:</h4>
            - Wird hinzugefügt sobald erhalten -
            <h4>Haftungsausschluss:</h4>
            Die Informationen auf dieser Webseite wurden sorgfältig geprüft und
            werden regelmäßig aktualisiert. Dennoch kann keine Garantie für die
            Vollständigkeit, Richtigkeit und Aktualität der Angaben übernommen
            werden.
            <h4>Urheberrecht:</h4>
            Die Inhalte und Werke auf dieser Webseite unterliegen dem deutschen
            Urheberrecht. Die Vervielfältigung, Bearbeitung, Verbreitung und
            jede Art der Verwertung außerhalb der Grenzen des Urheberrechts
            bedürfen der schriftlichen Zustimmung des jeweiligen Autors bzw.
            Erstellers.
            <h4>Datenschutz:</h4>
            Die Nutzung dieser Webseite ist in der Regel ohne Angabe
            personenbezogener Daten möglich. Soweit auf dieser Seite
            personenbezogene Daten (beispielsweise Name, Anschrift oder
            E-Mail-Adresse) erhoben werden, erfolgt dies, soweit möglich, stets
            auf freiwilliger Basis.
          </DialogContent>
          <DialogActions>
            <Button autoFocus onClick={handleClose}>
              Close
            </Button>
          </DialogActions>
        </BootstrapDialog>
      </React.Fragment>

      <React.Fragment>
        <BootstrapDialog
          maxWidth={"lg"}
          onClose={handleCloseD}
          aria-labelledby="customized-dialog-title"
          open={openD}
        >
          <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
            Datenschutz
          </DialogTitle>
          <IconButton
            aria-label="close"
            onClick={handleCloseD}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
          <DialogContent dividers>
            <h1>Datenschutzerklärung</h1>

            <p>Verantwortliche Stelle im Sinne der Datenschutzgesetze ist:</p>
            <p>
              Marvin Baumgart<br></br>
              Moritzstr. 8<br></br>
              10969, Berlin<br></br>
              Deutschland<br></br>
              E-Mail: marvinbaumgart18@gmail.com<br></br>
              Telefon: +49 162 189 6476
            </p>

            <h2>1. Datenschutz auf einen Blick</h2>
            <h3>Allgemeine Hinweise</h3>
            <p>
              Die folgenden Hinweise geben einen einfachen Überblick darüber,
              was mit Ihren personenbezogenen Daten passiert, wenn Sie diese
              Website besuchen. Personenbezogene Daten sind alle Daten, mit
              denen Sie persönlich identifiziert werden können. Ausführliche
              Informationen zum Thema Datenschutz entnehmen Sie unserer unter
              diesem Text aufgeführten Datenschutzerklärung.
            </p>

            <h3>Datenerfassung auf dieser Website</h3>

            <h4>
              Wer ist verantwortlich für die Datenerfassung auf dieser Website?
            </h4>
            <p>
              Die Datenverarbeitung auf dieser Website erfolgt durch den
              Websitebetreiber. Dessen Kontaktdaten können Sie dem Impressum
              dieser Website entnehmen.
            </p>

            <h4>Wie erfassen wir Ihre Daten?</h4>
            <p>
              Ihre Daten werden zum einen dadurch erhoben, dass Sie uns diese
              mitteilen. Hierbei kann es sich z.B. um Daten handeln, die Sie in
              ein Kontaktformular eingeben. Andere Daten werden automatisch beim
              Besuch der Website durch unsere IT-Systeme erfasst. Das sind vor
              allem technische Daten (z.B. Internetbrowser, Betriebssystem oder
              Uhrzeit des Seitenaufrufs). Die Erfassung dieser Daten erfolgt
              automatisch, sobald Sie diese Website betreten.
            </p>

            <h4>Wofür nutzen wir Ihre Daten?</h4>
            <p>
              Ein Teil der Daten wird erhoben, um eine fehlerfreie
              Bereitstellung der Website zu gewährleisten. Andere Daten können
              zur Analyse Ihres Nutzerverhaltens verwendet werden.
            </p>

            <h4>Welche Rechte haben Sie bezüglich Ihrer Daten?</h4>
            <p>
              Sie haben jederzeit das Recht, unentgeltlich Auskunft über
              Herkunft, Empfänger und Zweck Ihrer gespeicherten
              personenbezogenen Daten zu erhalten. Sie haben außerdem ein Recht,
              die Berichtigung, Sperrung oder Löschung dieser Daten zu
              verlangen. Hierzu sowie zu weiteren Fragen zum Thema Datenschutz
              können Sie sich jederzeit unter der im Impressum angegebenen
              Adresse an uns wenden.
            </p>

            <h2>2. Allgemeine Hinweise und Pflichtinformationen</h2>

            <h3>Datenschutz</h3>
            <p>
              Die Betreiber dieser Seiten nehmen den Schutz Ihrer persönlichen
              Daten sehr ernst. Wir behandeln Ihre personenbezogenen Daten
              vertraulich und entsprechend der gesetzlichen
              Datenschutzvorschriften sowie dieser Datenschutzerklärung.
            </p>

            <h4>Hinweis zur verantwortlichen Stelle</h4>
            <p>
              Die verantwortliche Stelle für die Datenverarbeitung auf dieser
              Website ist:<br></br>
              Marvin Baumgart<br></br>
              Moritzstr. 8<br></br>
              10969, Berlin<br></br>
              Deutschland<br></br>
              E-Mail: marvinbaumgart18@gmail.com<br></br>
              Telefon: +49 162 189 6476
            </p>

            <h4>Widerruf Ihrer Einwilligung zur Datenverarbeitung</h4>
            <p>
              Viele Datenverarbeitungsvorgänge sind nur mit Ihrer ausdrücklichen
              Einwilligung möglich. Sie können eine bereits erteilte
              Einwilligung jederzeit widerrufen. Dazu reicht eine formlose
              Mitteilung per E-Mail an uns. Die Rechtmäßigkeit der bis zum
              Widerruf erfolgten Datenverarbeitung bleibt vom Widerruf
              unberührt.
            </p>

            <h4>Beschwerderecht bei der zuständigen Aufsichtsbehörde</h4>
            <p>
              Im Falle datenschutzrechtlicher Verstöße steht dem Betroffenen ein
              Beschwerderecht bei der zuständigen Aufsichtsbehörde zu.
              Zuständige Aufsichtsbehörde in datenschutzrechtlichen Fragen ist
              der Landesdatenschutzbeauftragte des Bundeslandes, in dem unser
              Unternehmen seinen Sitz hat. Eine Liste der
              Datenschutzbeauftragten sowie deren Kontaktdaten können folgendem
              Link entnommen werden: [Link zur Liste der
              Datenschutzbeauftragten].
            </p>

            <h4>Recht auf Datenübertragbarkeit</h4>
            <p>
              Sie haben das Recht, Daten, die wir auf Grundlage Ihrer
              Einwilligung oder in Erfüllung eines Vertrags automatisiert
              verarbeiten, an sich oder an einen Dritten in einem gängigen,
              maschinenlesbaren Format aushändigen zu lassen. Sofern Sie die
              direkte Übertragung der Daten an einen anderen Verantwortlichen
              verlangen, erfolgt dies nur, soweit es technisch machbar ist.
            </p>

            <h4>SSL- bzw. TLS-Verschlüsselung</h4>
            <p>
              Diese Seite nutzt aus Sicherheitsgründen und zum Schutz der
              Übertragung vertraulicher Inhalte, wie zum Beispiel Bestellungen
              oder Anfragen, die Sie an uns als Seitenbetreiber senden, eine
              SSL- bzw. TLS-Verschlüsselung. Eine verschlüsselte Verbindung
              erkennen Sie daran, dass die Adresszeile des Browsers von
              "http://" auf "https://" wechselt und an dem Schloss-Symbol in
              Ihrer Browserzeile. Wenn die SSL- bzw. TLS-Verschlüsselung
              aktiviert ist, können die Daten, die Sie an uns übermitteln, nicht
              von Dritten mitgelesen werden.
            </p>

            <h4>Auskunft, Sperrung, Löschung</h4>
            <p>
              Sie haben im Rahmen der geltenden gesetzlichen Bestimmungen
              jederzeit das Recht auf unentgeltliche Auskunft über Ihre
              gespeicherten personenbezogenen Daten, deren Herkunft und
              Empfänger und den Zweck der Datenverarbeitung und ggf. ein Recht
              auf Berichtigung, Sperrung oder Löschung dieser Daten. Hierzu
              sowie zu weiteren Fragen zum Thema personenbezogene Daten können
              Sie sich jederzeit unter der im Impressum angegebenen Adresse an
              uns wenden.
            </p>

            <h4>Widerspruch gegen Werbe-Mails</h4>
            <p>
              Der Nutzung von im Rahmen der Impressumspflicht veröffentlichten
              Kontaktdaten zur Übersendung von nicht ausdrücklich angeforderter
              Werbung und Informationsmaterialien wird hiermit widersprochen.
              Die Betreiber der Seiten "?
            </p>
          </DialogContent>
          <DialogActions>
            <Button autoFocus onClick={handleCloseD}>
              Close
            </Button>
          </DialogActions>
        </BootstrapDialog>
      </React.Fragment>

      <div className={"content"}>
        <hr className={"content-underline"} color="#CECED2"></hr>
        <p className={"content-underline"}>
          Copyright © 2024 Marvin Baumgart. Alle Rechte vorbehalten.
        </p>
        <span className="button-link" onClick={handleClickOpen}>
          Impressum
        </span>
        &nbsp;&nbsp;&nbsp;
        <span className="button-link" onClick={handleClickOpenD}>
          Datenschutz
        </span>
      </div>
    </div>
  );
}

export default Footer;
