import { useTranslation } from "react-i18next";
import "./style.css";

function Certificates() {
  const { t } = useTranslation();
  
  return (
    <div className={"content"}>
      <h1 className={"content-header"}>{t('certificates')}</h1>
      <hr className={"content-underline"} color="#CECED2"></hr>
      <div className="container">
        <div className="box margin-top-50 text-content-card-height ">
          <div className="grid-container-education">
            <div className="grid-item-education text-align-left">
              05/2016 - 08/2016
            </div>
            <div className="grid-item-education">
              Machine Learning Course
              <br></br>
              STANDFORD UNIVERSITY, [ONLINE] (USA)
            </div>
            <div className="grid-item-education text-align-left">
              01/2018 - 07/2018
            </div>
            <div className="grid-item-education">
              Deep Learning Nano Degree
              <br></br>
              UDACITY, [ONLINE] (USA)
            </div>
            <div className="grid-item-education text-align-left">
              05/2022 - 05/2022
            </div>
            <div className="grid-item-education">
              iSAQB Certified Professional for Software Architecture -
              Foundation Level (DE)
            </div>
            <div className="grid-item-education text-align-left">06-09-2023</div>
              <div className="grid-item-education">
                CPSA Advanced SOFT (Soft Skills)
                <br></br>
                iSAQB
              </div>
              <div className="grid-item-education text-align-left">20-09-2023</div>
              <div className="grid-item-education">
                CPSA Advanced WEBSEC (Web Security)
                <br></br>
                iSAQB
              </div>
          </div>
        </div>
        <div className="box align-children-vertically"></div>
      </div>
    </div>
  );
}

export default Certificates;
