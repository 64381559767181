import { useTranslation } from "react-i18next";
import "./style.css";

function Work() {
  const { t } = useTranslation();

  return (
    <div className={"content"}>
      <h1 className={"content-header"}>{t('work')}</h1>
      <hr className={"content-underline"} color="#CECED2"></hr>
      <div className="container gap-125">
        <div className="box margin-top-50 text-content-card-height">
          <div className="description-box">
            <p className="description-box-date">11.2020 – 01.07.2024</p>
            <span className="description-box-header">
              Knesebeckstraße 1, 10623 Berlin
            </span>
            <h4 className="description-box-title">{t('nortal.title')}</h4>
            <b className="description-box-sub-header">
              {t('nortal.bmHeader')}
            </b>
            <p className="description-box-text no-margin">
              {t('nortal.bmDescription')}
            </p>
            <b className="description-box-sub-header">{t('nortal.amnogHeader')}</b>
            <p className="description-box-text no-margin">
              {t('nortal.amnogDescription')}
              <br></br>
              <a className="button-link" target="_blank" rel="noreferrer" href="https://extern.portal.g-ba.de/">
                https://extern.portal.g-ba.de/
              </a>
            </p>
          </div>
        </div>
        <div className="box margin-top-50 text-content-card-height mobile-margin-top">
          <div className="description-box">
            <p className="description-box-date">05.2018 – 09.2020</p>
            <span className="description-box-header">
              Adessoplatz 1, Dortmund
            </span>
            <h4 className="description-box-title">{t('adesso.title')}</h4>
            <p className="description-box-text no-margin">
              {t('adesso.description')}
            </p>
          </div>
          <div className="description-box margin-top-50 mobile-margin-top">
            <p className="description-box-date">03.2016 – 04.2018</p>
            <span className="description-box-header">
              3410 Hillview Ave., Palo Alto, 94304
            </span>
            <h4 className="description-box-title">{t('sap.title')}</h4>
            <p className="description-box-text no-margin">
              {t('sap.description')}
              <br></br>
              <a className="button-link" target="_blank" rel="noreferrer"  href="https://patents.google.com/?inventor=marvin+baumgart&oq=marvin+baumgart">
                {t('patents')}
              </a>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Work;
